/* .box h2::after {
    content: "";
    width: 5px;
    height: 20px;
    background: #ec7fff;
    display: inline-block;
  } */


  .box h1.dark {
    /* display: flex;
    align-items: center;
    gap: 2px; */
    font-family: "Lucida Console", "Courier New", monospace;
    animation: cursor-blink 1.5s steps(2) infinite;
    color: rgb(130, 253, 7);
    font-size: 2.5em;
  }

  .box h1.light {
    /* display: flex;
    align-items: center;
    gap: 2px; */
    font-family: "Lucida Console", "Courier New", monospace;
    animation: cursor-blink 1.5s steps(2) infinite;
    color: rgb(12, 14, 10);
    font-size: 2.5em;
  }

  .box h1.not404 {
    /* display: flex;
    align-items: center;
    gap: 2px; */
    font-family: "Lucida Console", "Courier New", monospace;
    animation: cursor-blink 1.5s steps(2) infinite;
    color: rgb(0, 0, 0);
    font-size: 2.5em;
  }

  @keyframes cursor-blink {
    50% {
      opacity: 0;
    }
  }
  