.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App p.fontfamily {
  font-family: 'Courier New', Courier, monospace;
  
}



.App-dark {
  background-image: url('../images/logo-color.png');
  background-repeat: repeat;
  background-size: 10% 10%;
  color: #fff;
}


.App-light {
  background-image: url('../images/logo-color-white.png');
  background-repeat: repeat;
  background-size: 10% 10%;
  color: #121212;
}
