.about {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    /* justify-content: left; */
    font-size: calc(10px + 2vmin);
  }

  .about img {
    float: right;
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 0px;
  }

  .about p {
    float: left;
    margin-top: 100px;
    margin-right: 100px;
    margin-left: 100px;
    font-family: 'Courier';
    line-height: 1.5;
  }
  .about h1 {
    float: left;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    font-family: 'Courier';
    flex-direction: column;
    line-height: 1.5;
  }

  .aboutmobile img {
    margin-top: 50px;
    margin-left: 0px;
  }

  .aboutmobile p {
    float: left;
    margin-top: 10px;
    margin-right: 100px;
    margin-left: 100px;
    font-family: 'Courier';
    line-height: 1.0;
  }

  .aboutmobile {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
  .App-dark-mobile {
    background-image: url('../images/logo-color.png');
    background-repeat: repeat;
    background-size: 10% 10%;
    color: #fff;
  }

  .App-light-mobile {
    background-image: url('../images/logo-color-white.png');
    background-repeat: repeat;
    background-size: 10% 10%;
    color: #121212;
  }