.notfound {
    background-image: url('../images/Ch3_lander_Right.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px;
  }
  
  .notfound h1 {
    font-size: 100px;
    margin-right: 0px;
    margin-left: 0px;
    font-family: 'Courier';
    margin-top: 300px;
    margin-bottom: 0px;
  }