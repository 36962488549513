



.topnav {
    background-color: #393636;
    overflow: hidden;
  }
  
  .topnav a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    margin-top: 30px;
  }

  .topnav a:hover {
    background-color: #561d1d;
    color: rgb(129, 195, 17);
  }

  .topnav img.splitright {
    float: right;
    color: white;
    margin-right: 15px;
    margin-top: 15px;
    border-radius: 10px;
    cursor: pointer;
  }

  .topnav a.splitleft {
    float: left;
    /* background-color: #ffffff; */
    /* background-image: '../images/sun.png'; */
    color: white;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .darkmode {
    background-color: #2a2319;
    margin-bottom: 10px;
  }

  .lightmode {
    background-color: #D3D3D3;
    margin-bottom: 10px;
  }